@use 'theme/desktop/variables.module.sass' as vrbs
@use 'theme/mobile/variables.module.sass' as mobileTheme

%footerFont
  font-size: 1.75rem
  color: vrbs.$footerFontColor

.root
  width: 100%
  position: relative
  bottom: 0
  left: 0
  padding: 3rem 3rem
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: mobileTheme.$bottomNavigationHeight
  .email
    color: #B4BDEC
  .announcement
    color: #7D8CD7

  div
    @extend %footerFont
    text-align: center

    &:not(:first-child)
      margin-top: 1rem

  .vendor
    align-self: center
    width: 20.75rem

    img
      max-width: 100%

  .rating
    width: 5rem
    height: 5rem

    img
      max-width: 100%
      height: auto

  .reCaptchaInfo
    color: #7D8CD7

    > a
      text-decoration: none
      color: #7D8CD7
  .copyRight
    color: #7D8CD7
