@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/function.sass' as func

$mobileWidth: func.strip-unit(v.$mobileWidth)

$borderRadius: func.px2vw(20.9, $mobileWidth)
$rankGoldBorder: func.px2vw(1, $mobileWidth) solid #F8E85D
$rankSilverBorder: func.px2vw(1, $mobileWidth) solid #D4DBE1
$rankBronzeBorder: func.px2vw(1, $mobileWidth) solid #F8B26C

.listContainer
  margin: func.px2vw(20.9, $mobileWidth) 0
  border-radius: $borderRadius
  position: relative
  .list
    display: grid
    place-items: center
    grid-template-rows: auto
    grid-template-columns: 30% auto
    &:nth-of-type(1)
      &>div
        &:nth-of-type(1)
          border-top-left-radius: $borderRadius
          overflow: hidden
        &:nth-of-type(2)
          border-top-right-radius: $borderRadius
          overflow: hidden
    &:nth-last-of-type(1)
      &>div
        &:nth-of-type(1)
          border-bottom-left-radius: $borderRadius
          overflow: hidden
        &:nth-of-type(2)
          border-bottom-right-radius: $borderRadius
          overflow: hidden
    &:nth-of-type(odd)
      &>div
        &:nth-of-type(2)
          background: #00234A
    &:nth-of-type(even)
      &>div
        &:nth-of-type(2)
          background: #052A53
    .label
      height: 100%
      width: 100%
      font: normal normal bold func.px2vw(35.5, $mobileWidth) Microsoft JhengHei UI
      background: transparent linear-gradient(180deg, #2656C8 0%, #080F71 100%) 0% 0% no-repeat padding-box
      border: func.px2vw(1, $mobileWidth) solid #2656C8
      opacity: 1
      grid-column: 1
      color: #F7F7C2
      display: grid
      place-items: center
      padding-bottom: func.px2vw(3, $mobileWidth)
      text-shadow: func.px2vw(-1, $mobileWidth) func.px2vw(-1, $mobileWidth) 0px #052447
    .content
      height: 100%
      display: grid
      place-items: center
      font-size: func.px2vw(36.5, $mobileWidth)
      width: 100%
      text-align: center
      border: func.px2vw(1, $mobileWidth) solid #113998
      opacity: 1
      padding: func.px2vw(12.5, $mobileWidth) func.px2vw(20.9, $mobileWidth)
      &.rowspan
        padding: 0
.rankGoldBorder
  border-left: $rankGoldBorder
  border-right: $rankGoldBorder
  &:nth-of-type(1)
    border-top: $rankGoldBorder
    border-top-left-radius: $borderRadius
    border-top-right-radius: $borderRadius
  &:nth-last-of-type(1)
    border-bottom: $rankGoldBorder
    border-bottom-left-radius: $borderRadius
    border-bottom-right-radius: $borderRadius
.rankSilverBorder
  border-left: $rankSilverBorder
  border-right: $rankSilverBorder
  &:nth-of-type(1)
    border-top: $rankSilverBorder
    border-top-left-radius: $borderRadius
    border-top-right-radius: $borderRadius
  &:nth-last-of-type(1)
    border-bottom: $rankSilverBorder
    border-bottom-left-radius: $borderRadius
    border-bottom-right-radius: $borderRadius
.rankBronzeBorder
  border-left: $rankBronzeBorder
  border-right: $rankBronzeBorder
  &:nth-of-type(1)
    border-top: $rankBronzeBorder
    border-top-left-radius: $borderRadius
    border-top-right-radius: $borderRadius
  &:nth-last-of-type(1)
    border-bottom: $rankBronzeBorder
    border-bottom-left-radius: $borderRadius
    border-bottom-right-radius: $borderRadius
