@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/function.sass' as func

$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative
  width: 95%
  margin: 0 auto
  padding: func.px2vw(25, $mobileWidth) 0
  min-height: inherit

  .wrapper
    display: flex
    flex-direction: column
    gap: func.px2vw(40, $mobileWidth)

  .errorMsg
    color: #FF0000
    font-size: func.px2vw(32, $mobileWidth)

  .titleBar
    color: #FDD83B
    text-align: center

    h1, h3
      margin: 0

    h1
      font-size: func.px2vw(56, $mobileWidth)

    h3
      font-size: func.px2vw(40, $mobileWidth)

  .searchBar
    display: flex
    flex-direction: column
    align-items: center
    gap: func.px2vw(18, $mobileWidth)

    .textContainer
      width: 100%
      display: flex
      flex-direction: column
      gap: func.px2vw(8, $mobileWidth)

      p
        margin: 0

    .button
      user-select: none
      background: transparent url('/image/btn/btn_news.png') no-repeat
      background-size: 100% 100%
      width: func.px2vw(300, $mobileWidth)
      height: func.px2vw(105, $mobileWidth)
      font-size: func.px2vw(28, $mobileWidth)

      &:hover
        background: transparent url('/image/btn/btn_news_hover.png') no-repeat
        background-size: 100% 100%

  .resultBar
    display: flex
    flex-direction: column
    gap: func.px2vw(20, $mobileWidth)

    h5
      margin: 0
      font-size: func.px2vw(32, $mobileWidth)
      font-weight: normal

    .resultBarChild
      flex: 1
      display: flex
      flex-direction: column
      row-gap: func.px2vw(8, $mobileWidth)

      .label
        color: #F7F7C2

      p
        font-size: func.px2vw(39.2, $mobileWidth)
        text-align: center
        margin: 0
        padding: func.px2vw(8, $mobileWidth)
        background: #787878
        border-radius: func.px2vw(8, $mobileWidth)
