@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/function.sass' as func

$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  min-height: inherit
  position: relative

  p
    display: block
    text-align: center
    margin: 0
    font-size: func.px2vw-lock(40, $mobileWidth)
