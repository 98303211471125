@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/function.sass' as func

$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative
  text-align: center

  .title, .subtitle
    margin: 0
    color: #FDD83B

  .title
    font-size: func.px2vw(56, $mobileWidth)

  .subtitle
    font-size: func.px2vw(40, $mobileWidth)

  .activityDate
    color: #fff
    font-size: func.px2vw(32, $mobileWidth)
