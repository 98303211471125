@use 'theme/common/function.sass' as func
@use 'theme/common/variables.module.sass' as v

%menuBtn
  color: #FFFFFF
  border-radius: 0px

$desktopWidth: func.strip-unit(v.$desktopWidth)

.menu
  display: flex
  flex-shrink: 1
  flex-grow: 1

  .btn
    @extend %menuBtn
    height: 100%
    font-size: func.px2vw-lock(22, $desktopWidth)
    font-weight: bold
    padding: func.px2vw-lock(21, $desktopWidth) func.px2vw-lock(30, $desktopWidth)
    letter-spacing: 0.125rem

    &:hover
      transition: background 0.5s
      background: linear-gradient(0deg, #CB30FF 0%, #8330FF 100%)
      font-weight: bold

.popoverList
  display: flex
  flex-direction: column
  border-radius: 0px
  background: rgba(#001B2C, 85%)

  & > div
    @extend %menuBtn
    font-size: 1.25rem
    font-weight: bold
    text-align: center
    padding-top: 0.75rem
    padding-bottom: 0.75rem
    background: #3E0976
    margin-bottom: 1px
    opacity: 0.85
    width: 100%

    &:hover
      font-weight: bold
      background: #FF00AA
