@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/function.sass' as func

$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  width: 100%
  height: 100%
  position: relative

  .label
    color: #F7F7C2
    margin-bottom: func.px2vw(10.45, $mobileWidth)
    font-size: func.px2vw(36.5, $mobileWidth)

  input
    width: 100%
    background: #001B2C
    padding: func.px2vw(15.68, $mobileWidth)
    border: func.px2vw(1, $mobileWidth) solid #59D8E6
    border-radius: func.px2vw(10, $mobileWidth)
    color: #FFF
    font-size: func.px2vw(36, $mobileWidth)

    &:focus-visible
      outline: none
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      -moz-appearance: textfield
      margin: 0
