@use 'theme/common/deviceSize.module.sass' as device
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/function.sass' as func

$desktopWidth: func.strip-unit(v.$desktopWidth)
$mobileWidth: func.strip-unit(v.$mobileWidth)

.root
  position: relative
  display: flex
  justify-content: center
  align-items: center
  height: func.px2vw-lock(809, $mobileWidth)

  +device.desktop
    height: func.px2vw-lock(809, $desktopWidth)

  .loadingImage
    width: func.px2vw-lock(50, $mobileWidth)
    height: func.px2vw-lock(50, $mobileWidth)
